<template>
  <div class="recomendations">
    <div class="row justify-space-between">
      <div class="col">
        <h1>Рекомендации для руководителя по работе с сотрудником</h1>
      </div>
      <div class="col load-pdf">
      <v-btn v-if="$store.getters['auth/isAllowed']('change_psychotyperecommendation')"
        color="primary"
        :loading="loading.recomendationsRefresh"
        @click="$emit('refresh')" class="mr-2" icon
      >
        <!-- Обновить -->
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.7324 16.0689C14.7347 15.8546 16.4 14.5978 16.4 12.7953H17.2C17.2 15.1886
          15.0154 16.6291 12.8176 16.8644C10.8365 17.0764 8.63094 16.3435 7.62765 14.3118L7.34549 14.7952L6.6546 14.3918L7.35443 13.193L7.53254 12.8879L7.85731 13.027L9.25731 13.6264L8.94243 14.3618L8.44979 14.1509C9.31403 15.6614 11.0476 16.2493
          12.7324 16.0689ZM8.09996 11.7048C8.09996 9.90229 9.76527 8.64547 11.7676 8.43114C13.4524 8.2508 15.186 8.8387 16.0502 10.3492L15.5576 10.1382L15.2427 10.8736L16.6427 11.4731L16.9675 11.6121L17.1456 11.307L17.8454 10.1082L17.1545 9.7049L16.8723
          10.1882C15.8691 8.15659 13.6635 7.42364 11.6824 7.63569C9.48464 7.87093 7.29996 9.31145 7.29996 11.7048H8.09996Z" fill="var(--v-primary-base)"/>
        </svg>
      </v-btn>
      <!-- <PdfButton v-if="recs && recs.length && $store.getters['auth/isAllowed']('download_recommendations')"
        :loading="loading.loadPdf" class="" @downloadPdf="$emit('loadPdf', $event)"
      /> -->
    </div>
    </div>
    <div class="section" v-for="(rec, ind) in recs" :key="ind">
      <div class="title marker text-blue">
        {{ rec.title }}
        <v-btn v-if="isEditable && $store.getters['auth/isAllowed']('delete_chapter')" class="ml-3" text icon x-small @click="onEdit(rec)">
            <v-icon dense class="mb-1">mdi-pencil</v-icon>
          </v-btn>
      </div>
      <ul>
        <li v-for="(text, indText) in rec.text" :key="indText">
          {{ text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import PdfButton from '@/components/ui/PdfButton';
import { mapGetters } from 'vuex';

export default {
  components: {
    PdfButton
  },
  props: {
    recs: Array,
    isEditable: Boolean,
  },
  computed: {
    ...mapGetters({
      loading: 'users/candidate/loading',
    }),
  },
  methods: {
    onEdit(rec) {
      this.$emit('editItem', rec);
    }

  }
};
</script>

<style lang="scss">
.recomendations {
  position: relative;
  max-width: 1024px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.87);

  .load-pdf {
    // position: absolute;
    // top: 0px;
    // right: 20px;
    flex: 0 0 360px;
    display: flex;
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
  }

  .text-bold {
    font-weight: 600;
  }

  .bg-blue {
    background-color: #f5f8ff;
  }

  .text-blue {
    color: var(--v-primary-base);
  }

  .marker {
    position: relative;
  }

  .marker:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -20px;
    width: 8px;
    height: 8px;
    border: 3px solid var(--v-primary-base);
    display: inline-block;
    transform: rotate(45deg);
    margin-right: 5px;
  }

  .section {
    position: relative;
    margin: 20px 20px 20px 20px;
    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      margin-bottom: 16px;
      &.marker:before {
        top: 9px;
      }
      &:after {
        content: '';
        width: 100px;
        border-bottom: 3px solid var(--v-primary-base);
        display: block;
        margin-top: 12px;
      }
    }

    ul {
      padding-left: 16px;
      li {
        margin: 7px 0;
        line-height: 140%;
      }
    }
  }
}
</style>
