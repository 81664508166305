<template>
  <div class="auth-card large text-center" :class="{android: !$store.getters['auth/isTA']}">
    <h2 class="auth-card__title mb-8">Здравствуйте!</h2>

    <div class="font-weight-600 text-primary mb-8">
      Спасибо, что согласились выполнить тест.
      <br>Пожалуйста, следуйте инструкциям в заданиях.
      <br>Успехов!
    </div>
    <div class="android" />
    <form @submit.prevent="submit">
      <v-btn class="btn-disabled-primary mb-4" type="submit" x-large color="primary" :disabled="!checkbox">
        Начать тестирование
      </v-btn>
      <v-checkbox v-model="checkbox" label="С политикой обработки персональных данных согласен (-на)" />
    </form>
    <div class="text-light font-sm">
      Нажимая кнопку «Начать тестирование», даю свое согласие на обработку персональных данных,
      указываемых мною при прохождении теста на данном сайте в порядке и целях, указанных в
      <a target="_blank" href="/personal_policy.html">Политике в отношении обработки персональных данных</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Policy',
  data: () => ({
    checkbox: false,
  }),
  methods: {
    ...mapActions({
      nextStep: 'testing/testing/nextStep',
    }),
    submit() {
      this.nextStep();
    },
  },
};
</script>
