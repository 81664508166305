<template>
    <div v-if="results" class="test-editor-results">
        <div class="row mt-2 mt-md-8">
            <div class="col-12 pa-3">
                <h3 class="profile-board__title">{{ tabInfo.title }}</h3>
                <hr class="mt-7 mb-4">
                <div v-if="tabInfo.testData">

                    <!-- <pre>{{ tabInfo.testData }}</pre> -->
                    <div class="profnavigator-list">
                        <div v-for="(prof, indProf) in positions" :key="indProf" class="profnavigator-item">
                            <div v-for="block in 10" :key="block" class="profnavigator-item-block"
                                :class="getBlockClass(prof, indProf, block)" />
                            <div class="profnavigator-item-value">{{ getProfValue(prof) }}</div>
                            <div class="profnavigator-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        </div>
                    </div>
                    <h4 class="desc-title">Описание</h4>
                    <p>Ниже представлены 3 ключевых направления деятельности, к которым Вы проявили наибольший интерес. Развиваясь в данных направлениях, Вы можете добиться большего успеха, так как данные направления деятельности Вас будут мотивировать в Вашей работе
                        Для успешного освоения профессий Вам может понадобиться пройти дополнительное обучение, обратитесь за информацией к консультанту или работодателю.
                    </p>
                    <div v-for="(prof, indProf) in top3positions" :key="indProf" class="desc-item">
                        <div class="desc-item-title">{{ prof.block ? prof.block.title : "Без названия" }}</div>
                        <div class="desc-item-text" v-html="prepareText(prof.text)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';

export default {
    name: 'TestEditorResult',
    components: {
    },
    props: ['tabInfo'],
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            results: 'users/candidate/results',
        }),
        positions() {
            const arr = [...this.tabInfo.testData]
            arr.sort((a, b) => b?.value - a?.value)
            return arr
        },
        top3positions() {
            return this.positions.slice(0, 3)
        }
    },
    methods: {
        getProfValue(item) {
            let v = Math.round(item.value)
            if (v < 0) v = 0;
            if (v > 10) v = 10;
            return v
        },
        getBlockClass(prof, indProf, indBlock) {
            const v = this.getProfValue(prof)
            if (indBlock <= v) return indProf === 0 ? 'profnavigator-item-block-dark' : 'profnavigator-item-block-blue'
            return 'profnavigator-item-block'
        },
        prepareText(text) {
            const lines = text.split('\n');
            let html = '';
            lines.forEach(line => {
                const spaces = line.match(/^\s*/)[0].length;
                const indent = '&nbsp;&nbsp;'.repeat(spaces);
                html += `<p>${indent}${line.trim()}</p>`;
            });

            return html;
        }
    },
};
</script>

<style lang="scss">
@import "src/assets/styles/variables";


.test-editor-results h3 {
    font-size: 1.6rem;
}

.test-editor-results .profnavigator-item {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.test-editor-results .profnavigator-item-block {
    background: var(--color-block-light);
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-block-blue);
    border-radius: 5px;
    margin-right: 1px;
    flex-shrink: 0;
}

.test-editor-results .profnavigator-item-block-dark {
    background: var(--color-block-dark);
    border-color: var(--color-block-dark);
}

.test-editor-results .profnavigator-item-block-blue {
    background: var(--color-block-blue);
    border-color: var(--color-block-blue);
}

.test-editor-results .profnavigator-item-value {
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
}

.test-editor-results .profnavigator-item-title {
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
}

.test-editor-results .profnavigator-item-block-dense {
    width: 20px;
    height: 20px;
}


.test-editor-results .desc-title {
    font-size: 20px;
    font-weight: bold;
}

.test-editor-results .desc-item-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0 8px 0;
}

.test-editor-results .desc-item {
    background: var(--color-bg);
    padding: 20px 10px 10px 20px;
    margin: 20px 0;
    border-radius: 5px;
}

.test-editor-results .desc-item-text {
    padding: 10px;
    border-radius: 10px;
}

.test-editor-results .desc-item-text p {
    margin-bottom: 5px !important;
}


@media screen and (max-width: 800px) {
    .test-editor-results .profnavigator-item-block {
        width: 28px;
        height: 30px;
    }
    .test-editor-results .profnavigator-item-block-dense {
        width: 12px;
        height: 20px;
    }
}

@media screen and (max-width: 600px) {
    .test-editor-results .profnavigator-item-block {
        width: 13px;
        height: 16px;
    }

    .test-editor-results .profnavigator-item {
        margin: 10px 0;
    }

    .test-editor-results .profnavigator-item-value {
        margin-left: 10px;
        font-size: 14px;
    }

    .test-editor-results .profnavigator-item-title {
        margin-left: 10px;
        font-size: 14px;
    }
    .test-editor-results .profnavigator-item-block-dense {
        width: 10px;
        height: 16px;
    }

}
</style>