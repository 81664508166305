<template>
  <div class="scale mb-6">
    <div v-if="data.title" class="scale__title mr-10">{{data.title}}</div>

    <div class="scale__wrapper">
      <div class="scale__line" :class="{color}">
        <div class="scale__line-progress" ref="progress"></div>
        <div v-for="(m, ind) in data.markers" :key="ind" class="circle marker" :style="`left: ${m.value}%`">
          <div class="marker__text">{{m.text}}</div>
        </div>
      </div>
      <div class="scale__number font-weight-600 ml-auto" :class="colorValue">
        {{data.value}}%
      </div>
    </div>

    <div v-if="data.description" class="row mr-10">
      <div class="scale__description text-dark-gray col-sm-8 mt-2">
        {{data.description}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Scale',
  props: {
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorValue() {
      if (this.color) {
        return this.data.value >= 50 ? 'success--text' : 'error--text';
      }
      return '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.progress.style.width = `${100 - this.data.value}%`;
    }, 0);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.scale {
  .marker {
    position: absolute;
    top: -7px;
    overflow: visible;
      transform: translateX(-50%) ;

    &__text {
      font-size: $font-sm;
      //font-size: $font-xs;
      color: rgba(0, 0, 0, 0.87);
      width: 150px;
      word-break: normal;
      text-align: center;
      position: absolute;
      bottom: -8px;
      transform: translateX(-50%) translateX(10px) translateY(-30px);
      @media (min-width: $break-point-xxs) {
        font-size: $font-xs;
      }
    }
  }
  .circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid var(--v-primary-base);
  }
  &__wrapper {
    display: flex;
    position: relative;
    margin-top: 50px;
  }
  &__title {
    font-size: $font-sm;
    font-weight: 600;
    text-align: center;
    //@media (min-width: $break-point-sm) {
    //  font-size: $font-sm;
    //}
  }

  &__line {
    position: relative;
    width: calc(100% - 80px);
    margin-left: 20px;
    height: 6px;
    border-radius: 4px;
    color: $color-light-gray;
    margin-top: 10px;
    background: linear-gradient(90deg, #B7F4F8 0%, #18A0FB 100%);
    &.color {
      background: linear-gradient(90deg, #FF4D00 0%,
        rgba(255, 184, 0, 0.98) 27.08%,
        #FFF503 64.06%, #ADFF00 100%),
      linear-gradient(90deg, #B7F4F8 0%, #18A0FB 100%);
    }
    &-progress {
      transition: 1s width;
      width: 100%;
      height: 100%;
      border-radius: 0 2px 2px 0;
      margin-left: auto;
      background: currentColor;
    }
  }
  &__number {
    color: $color-blue;
    font-size: $font-sm;
    @media (min-width: $break-point-sm) {
      font-size: $font-default;
    }
  }

  &__description {
    font-size: $font-xs;
  }
}
</style>
