<template>
  <div>
    <v-data-table
      :headers="showHeaders"
      :items="candidates"
      class="px-0 px-sm-5"
      sort-by="id"
      sort-desc
      :loading="loading.load"
      loading-text="Загрузка... Пожалуйста подождите"
      :mobile-breakpoint="300"
      :page="filter.page"
      :items-per-page="filter.pageSize"
      :height="tableHeight"
      fixed-header
      @pagination="pagination"
      hide-default-footer
      :server-items-length="count"
      :_footer-props="{ itemsPerPageOptions: [25] }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <CandidatesTableActions :active="!!selected.length" class="d-none d-sm-block" />
          <v-spacer></v-spacer>
          <CandidateCreateModal />

          <CandidateDeleteModal />
          <div class="d-flex align-center">
            <div class="mr-1 d-none d-sm-block">Показывать:</div>
            <v-select v-model="selectedHeaders" :items="headers2select"
              :_item-text="(item) => getColumnNameForFilter(item)"
              multiple outlined dense hide-details style="width: 420px; max-width:80vw;"
              @change="changeSelectedHeaders"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" small><span>{{ item.text ? item.text : item.text2 }}</span></v-chip>
                <span v-if="index === 2" class="grey--text caption">(+еще {{ selectedHeaders.length - 2 }})</span>
              </template>

              <template v-slot:item="{ active, item, attrs }">
                <div class="d-flex align-center w-100">
                  <div><v-checkbox hide-details class="ma-0 mb-1" :input-value="(attrs.inputValue)"></v-checkbox></div>
                  <div class="d-flex align-center  justify-space-between w-100">
                    <div>{{item.text ? item.text : item.text2}}</div>
                    <v-chip small color="primary" v-if="isFilterOn(item)">Фильтр включен</v-chip>
                  </div>
                </div>
              </template>

            </v-select>
          </div>
        </v-toolbar>
      </template>

      <!-- Header -->
      <template v-slot:header>
        <tr>
          <td class="border-bottom pa-4" v-if="selectedHeaders.find(s => s==='check')">
            <div>
              <v-checkbox
                :input-value="selected.length"
                @change="onChangeAllCheck"
                :indeterminate="!!selected.length && selected.length !== count"
                hide-details
                class="mt-auto"
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='fullname')">
            <div>
              <v-text-field
                label="Фамилия и Имя"
                append-icon="mdi-magnify"
                class="font-sm"
                :value="filter.name"
                @input="debouncedSetFilterName"
                dense
                hide-details
                outlined
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='active')">
            <div>
              <v-select :items="activeItems" label="Активный" outlined hide-details dense
                :value="filter.is_active"
                :return-object="false"
                @change="filterActive"
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='email')">
            <div>
              <v-text-field
                label="Электронная почта"
                append-icon="mdi-magnify"
                class="font-sm"
                :value="filter.email"
                @input="debouncedSetFilterEmail"
                dense hide-details outlined
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='company')">
            <div>
              <v-text-field
                label="Компания"
                append-icon="mdi-magnify"
                class="font-sm"
                :value="filter.company"
                @input="debouncedSetFilterCompany"
                hide-details outlined dense
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='position')">
            <div>
              <v-text-field
                label="Должность"
                append-icon="mdi-magnify"
                class="font-sm"
                dense
                :value="filter.position"
                @input="debouncedSetFilterPosition"
                hide-details
                outlined
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='manager')">
            <div>
              <v-text-field
                label="Менеджер"
                append-icon="mdi-magnify"
                class="font-sm"
                dense
                :value="filter.manager"
                @input="debouncedSetFilterManager"
                hide-details
                outlined
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='date_joined')">
            <!-- Дата регистрации -->
            <v-menu v-model="datePickerShow" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateJoinedFilter.join(' - ')"
                  label="Дата регистрации"
                  append-icon="mdi-calendar"
                  :prepend-inner-icon="dateJoinedFilter.length ? 'mdi-close' : null"
                  @click:prepend-inner="clearJoinedDateFilter"
                  class="font-sm"
                  dense readonly outlined hide-details
                  v-bind="attrs" v-on="on"
                />
              </template>

              <v-date-picker v-model="dateJoinedFilter" range no-title scrollable locale="ru-RU">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelJoinedDateFilter"> Отменить </v-btn>
                <v-btn text color="primary" @click="applyJoinedDateFilter" :disabled="dateJoinedFilter.length !== 2"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='testing_status')">
            <div class="other-width-col">
              <v-select
                v-model="selectedTestingStatuses"
                :items="testingStatuses"
                item-text="text"
                item-value="value"
                label="Статус тестирования"
                class="font-sm"
                hide-details
                :menu-props="{ top: false, closeOnClick: true }"
                dense
                multiple
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="testingSelectedAllToggle">
                    <v-list-item-action>
                      <v-icon :color="selectedTests.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconTestingStatuses }}
                      </v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Все</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <template v-slot:append-item>
                  <div class="text-center py-2">
                    <v-btn color="primary" small class="px-10" @click="loadCandidates">Применить</v-btn>
                  </div>
                </template>
              </v-select>
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='permissions')"></td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='sendTest')"></td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='comment')">
            <div>
              <v-text-field
                label="Комментарий"
                append-icon="mdi-magnify"
                class="font-sm"
                dense
                :value="filter.comment"
                @input="debouncedSetFilterComment"
                hide-details
                outlined
              />
            </div>
          </td>

          <td class="border-bottom pa-3" v-if="selectedHeaders.find(s => s==='actions')"></td>
        </tr>
      </template>

      <!--  Cells  -->
      <template v-slot:item.check="{ item }">
        <div>
          <v-checkbox :input-value="selected.some(id => id === item.id)" @change="setSelected(item.id)" hide-details class="mt-auto" />
        </div>
      </template>

      <template v-slot:item.fullname="{ item }">
        <div class="d-flex justify-space-between align-center">
          <router-link class="text-primary mr-2" :to="{ name: 'candidates-id', params: { id: item.id } }"> {{ item.last_name }} {{ item.first_name }} </router-link>
        </div>
      </template>

      <template v-slot:item.active="{ item }">
        <div class="d-flex align-center justify-center">
          <div class="status" :class="{ active: item.is_active }" />
        </div>
      </template>

      <template #item.company="{ item }">
        {{ item.manager && item.manager.customer && item.manager.customer.company ? item.manager.customer.company : '' }}
      </template>

      <template #item.manager="{ item }">
        {{ (item.manager) &&  `${item.manager.first_name} ${item.manager.last_name}` }}
      </template>

      <template v-slot:item.date_joined="{ item }">
        <div class="d-flex justify-space-between align-center">
          {{ $moment(item.date_joined).format('DD.MM.YYYY') }}
        </div>
      </template>

      <template v-slot:item.testing_status="{ item }">
        <div class="badge font-xs w-100" :class="testingStatusBadgeClass(item)">
          {{ testingStatus(item) }}
        </div>
      </template>

      <template v-slot:item.permissions="{ item }">
        <div class="">
          <v-switch color="primary" inset :input-value="item.permissions && item.permissions.length" :error-messages="permissionsErrorId===item.id ? 'Ошибка' : ''"
            @change="changePermissions(item, $event)" :loading="changingPermissions===item.id"
          />
          <!-- {{ item.permissions }} -->
        </div>
      </template>

      <template v-slot:item.sendTest="{ item }">
        <div class="d-flex align-center" v-if="item.is_active">
          <CreateTestingSessionButton :data="item" :loading="loadingSession.createSession" :sendTests="sendTests" />
          <div class="mt-1 ml-2">
            <a v-if="item.token" :href="`/test/${item.token}`" target="_blank">
              <v-icon color="primary">mdi-link-variant</v-icon>
            </a>
            <v-icon v-else>mdi-link-variant</v-icon>
          </div>
        </div>
        <span v-else class="grey--text lighten-2">Кандидат не активен</span>

      </template>

      <template v-slot:item.comment="{ item }">
        <div class="py-2 _pa-2 _other-width-col">
          <v-tooltip v-if="item.comment" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex" v-bind="attrs" v-on="on">
                <v-text-field label="Комментарий" class="font-xs" dense :value="item.comment" @input="debouncedSendComment($event, item)" hide-details outlined />
              </div>
            </template>
            <span>{{ item.comment }}</span>
          </v-tooltip>

          <v-text-field v-else label="Комментарий" class="font-xs" dense :value="item.comment" @input="debouncedSendComment($event, item)" hide-details outlined />
        </div>
      </template>

      <template v-slot:item.position="{ item }">
        <div class="py-2 _pa-2 _other-width-col">
          <v-text-field label="Должность" class="font-xs w-100" dense :value="item.position" @input="debouncedSendPosition($event, item)"
            hide-details outlined :loading="item.loading.editPosition"
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="$store.getters['auth/isAllowed']('delete_candidate')" @click="deleteCandidateDialog(item)">mdi-delete</v-icon>
      </template>

      <template v-slot:no-data> Кандидатов не найдено. </template>

      <template v-slot:footer="{ props }">
        <v-data-footer
          :pagination="props.pagination"
          :options="props.options"
          @update:options="updateOptions"
          items-per-page-text="Кандидатов на странице:"
          :items-per-page-options="[10, 20, 50, 100, 500, 1000]"
          :page-text="`Всего кандидатов: ${props.pagination.itemsLength}. Страница ${props.options.page} из ${props.pagination.pageCount}`"
          show-current-page
          show-first-last-page
        />
        <!-- <div class="text-center py-6">
        <v-pagination v-if="paginationData"
                      :value="filter.page" @input="setFilterPage"
                      :length="paginationData.pageCount" />
      </div> -->
      </template>
    </v-data-table>
    <MessageDialog v-model="showSessionError" :text="strSessionError" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { TESTING_STATUSES, TESTING_STATUSES_TITLES } from '@/common/constants/testingStatuses';
import CandidatesTableActions from '@/components/candidates/table/CandidatesTableActions';
import CreateTestingSessionButton from '@/components/candidates/table/CreateTestingSessionButton';
import CandidateCreateModal from '@/components/candidates/CandidateCreateModal';
import CandidateDeleteModal from '@/components/candidates/CandidateDeleteModal';
import MessageDialog from '@/components/ui/MessageDialog';

export default {
  name: 'CandidatesTable',
  components: {
    CandidatesTableActions,
    CreateTestingSessionButton,
    CandidateCreateModal,
    CandidateDeleteModal,
    MessageDialog
  },
  data: () => ({
    tableHeight: 400,
    testingStatuses: Object.entries(TESTING_STATUSES_TITLES).map(s => ({
      text: s[1],
      value: s[0]
    })),
    activeItems: [
      { text: 'Все', value: '' },
      { text: 'Активные', value: 'true' },
      { text: 'Неактивные', value: 'false' }
    ],
    selectedTests: [],
    selectedHeaders: [],
    headers: [
      {
        text: '',
        value: 'check',
        sortable: false,
        width: '60px',
        text2: 'Выбрать кандидата',
      },
      {
        text: 'Фамилия и Имя',
        value: 'fullname',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.name,
      },
      {
        text: 'Активность',
        value: 'active',
        sortable: false,
        width: '180px',
        checkFilter: (filter) => filter.is_active !== '',
      },
      {
        text: 'Электронная почта',
        value: 'email',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.email,
      },
      {
        text: 'Компания',
        value: 'company',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.company,
      },
      {
        text: 'Должность',
        value: 'position',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.position,
      },
      {
        text: 'Менеджер',
        value: 'manager',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.manager,
      },
      {
        text: 'Дата регистрации',
        value: 'date_joined',
        sortable: false,
        width: '280px',
        checkFilter: (filter) => filter.from_date_joined || filter.to_date_joined,
      },
      {
        text: 'Статус тестирования',
        value: 'testing_status',
        sortable: false,
        width: '220px',
        checkFilter: (filter) => filter.testing_status && filter.testing_status.length,
      },
      {
        text: '',
        value: 'sendTest',
        sortable: false,
        width: '220px',
        text2: 'Отправить тест',
      },
      {
        text: 'Права на просмотр отчетов',
        value: 'permissions',
        sortable: false,
        width: '80px',
      },
      {
        text: '',
        value: 'comment',
        sortable: false,
        width: '280px',
        text2: 'Комментарий',
        checkFilter: (filter) => filter.comment,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
        text2: 'Удалить',
      }
    ],
    paginationData: null,
    datePickerShow: false,
    dateJoinedFilter: [],

    datePickerDateFormat: 'YYYY-MM-DD',
    showSessionError: false,
    strSessionError: '',
    changingPermissions: 0,
    permissionsErrorId: 0,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      candidates: 'users/candidates/candidates',
      selected: 'users/candidates/selected',
      filter: 'users/candidates/filter',
      count: 'users/candidates/count',
      loadingSession: 'testing/session/loading',
      errorsTestingSession: 'testing/session/errors',
    }),
    headers2select() {
      return [...this.headers.slice(1), this.headers[0]];
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s.value));
    },
    selectedTestingStatuses: {
      get() {
        return this.filter.testing_status;
      },
      set(val) {
        this.setFilterTestingStatus(val);
      }
    },
    selectedAllTestingStatuses() {
      return this.selectedTestingStatuses.length === this.testingStatuses.length;
    },
    selectedSomeTestingStatus() {
      return this.selectedTestingStatuses.length > 0 && !this.selectedAllTestingStatuses;
    },
    iconTestingStatuses() {
      if (this.selectedAllTestingStatuses) return 'mdi-checkbox-marked';
      if (this.selectedSomeTestingStatus) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    ...mapActions({
      setSelected: 'users/candidates/setSelected',
      allSelected: 'users/candidates/allSelected',
      clearSelected: 'users/candidates/clearSelected',
      setFilterPage: 'users/candidates/setFilterPage',
      setFilterPageSize: 'users/candidates/setFilterPageSize',
      setFilterName: 'users/candidates/setFilterName',
      setFilterJoinedDatesRange: 'users/candidates/setFilterJoinedDatesRange',
      setFilterEmail: 'users/candidates/setFilterEmail',
      setFilterPosition: 'users/candidates/setFilterPosition',
      setFilterActive: 'users/candidates/setFilterActive',
      setFilterComment: 'users/candidates/setFilterComment',
      setFilterCompany: 'users/candidates/setFilterCompany',
      setFilterManager: 'users/candidates/setFilterManager',
      setFilterTestingStatus: 'users/candidates/setFilterTestingStatus',
      setFilterAll: 'users/candidates/setFilterAll',
      loadCandidates: 'users/candidates/loadCandidates',
      deleteCandidateDialog: 'users/candidates/deleteCandidateDialog',
      editCandidateComment: 'users/candidates/editCandidateComment',
      editCandidatePosition: 'users/candidates/editCandidatePosition',
      editCandidatePermission: 'users/candidates/editCandidatePermission',
      setCandidateTestingStatus: 'users/candidates/setCandidateTestingStatus',

      createTestingSession: 'testing/session/createTestingSession'
    }),

    async changePermissions(item, value) {
      if (this.changingPermissions) return;
      this.changingPermissions = item.id;
      this.permissionsErrorId = 0;
      const ret = await this.editCandidatePermission({ candidate: item, value });
      if (!ret) {
        this.permissionsErrorId = item.id;
      }
      this.changingPermissions = 0;
      return false;
    },

    isFilterOn(item) {
      const header = this.headers.find(el => el.value === item.value);
      return header && header.checkFilter && header.checkFilter(this.filter);
    },
    updateOptions(param) {
      this.setFilterPageSize({ page: param.page, pageSize: param.itemsPerPage });
    },
    initialize() {
      this.setFilterAll(this.$route.query);
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
    testingSelectedAllToggle() {
      this.$nextTick(() => {
        if (this.selectedAllTestingStatuses) {
          this.selectedTestingStatuses = [];
        } else {
          this.selectedTestingStatuses = this.testingStatuses.map(s => s.value);
        }
      });
    },
    // eslint-disable-next-line func-names
    debouncedSetFilterName: debounce(function (val) {
      this.setFilterName(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterEmail: debounce(function (val) {
      this.setFilterEmail(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterPosition: debounce(function (val) {
      this.setFilterPosition(val);
    }, 500),
    filterActive(val) {
      this.setFilterActive(val);
    },
    // eslint-disable-next-line func-names
    debouncedSetFilterComment: debounce(function (val) {
      this.setFilterComment(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterCompany: debounce(function (val) {
      this.setFilterCompany(val);
    }, 500),
    debouncedSetFilterManager: debounce(function (val) {
      this.setFilterManager(val);
    }, 500),
    testingStatus(item) {
      return TESTING_STATUSES_TITLES[item.testing_status];
    },
    clearJoinedDateFilter() {
      this.dateJoinedFilter = [];
      this.setFilterJoinedDatesRange(this.dateJoinedFilter);
      this.datePickerShow = false;
    },
    cancelJoinedDateFilter() {
      if (this.filter.from_date_joined && this.filter.to_date_joined) {
        this.dateJoinedFilter = [this.filter.from_date_joined, this.filter.to_date_joined];
      } else {
        this.dateJoinedFilter = [];
      }
      this.datePickerShow = false;
    },
    applyJoinedDateFilter() {
      this.dateJoinedFilter = this.dateJoinedFilter.sort((a, b) =>
        this.$moment(a, this.datePickerDateFormat).isBefore(this.$moment(b, this.datePickerDateFormat)) ? -1 : 1
      );

      this.setFilterJoinedDatesRange(this.dateJoinedFilter);
      this.datePickerShow = false;
    },
    testingStatusBadgeClass(item) {
      switch (item.testing_status) {
        case TESTING_STATUSES.COMPLETED:
          return 'badge-success';
        case TESTING_STATUSES.NOT_COMPLETED:
          return 'badge-primary';
        case TESTING_STATUSES.IN_PROGRESS:
          return 'badge-warning';
        case TESTING_STATUSES.NOT_SEND:
          return 'badge-error';
        case TESTING_STATUSES.NOT_STARTED:
          return '';
        default:
          return 'd-none';
      }
    },

    // eslint-disable-next-line func-names
    debouncedSendComment: debounce(function (text, item) {
      this.editCandidateComment({
        id: item.id,
        text
      });
    }, 1000),

    debouncedSendPosition: debounce(function (text, item) {
      console.log(1111);
      this.editCandidatePosition({
        id: item.id,
        text
      });
    }, 1000),

    async sendTests(data) {
      await this.createTestingSession(data).then(({ candidate, response }) => {
        this.setCandidateTestingStatus({
          id: candidate,
          data: response
        });
      }).catch(() => {
        this.showSessionError = true;
        this.strSessionError = this.errorsTestingSession.createSession
          && this.errorsTestingSession.createSession.length
          && this.errorsTestingSession.createSession[0].length > 1 ? this.errorsTestingSession.createSession[0][1] : 'Произошла ошибка';
      });
    },

    changeSelectedHeaders() {
      localStorage.setItem('showCandidateColumns', JSON.stringify(this.selectedHeaders));
    }
  },
  watch: {
    filter: {
      handler(val) {
        const query = {};
        for (const [key, value] of Object.entries(val)) {
          if (Array.isArray(value) && value.length) {
            query[key] = value.join(',');
          } else if (value || value === 0) {
            query[key] = value;
          }
        }
        this.$router
          .replace({
            name: this.$route.name,
            query
          })
          .catch(() => {});
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 420;
    this.tableHeight = Math.max(height, this.tableHeight);
    this.selectedHeaders = JSON.parse(localStorage.getItem('showCandidateColumns'));
    if (!this.selectedHeaders) {
      this.selectedHeaders = this.headers.map(s => s.value);
    }
  }
};
</script>
<style lang="scss">
.v-list.v-sheet {
  padding: 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.v-list-item__action {
  margin-top: 0;
  margin-bottom: 0;
}

.v-select__selections {
  flex-wrap: nowrap;
}

.other-width-col {
  width: 13rem;
}
</style>
