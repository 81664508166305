<template>
  <div class="header">
    <div class="row">
      <div class="col-12">
        <div>
          <router-link :to="{name: 'home'}"><img :src="logo" alt="Главная"></router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="back">
          <a @click="$router.go(-1)" class="d-flex align-center">
            <img class="mr-3" src="@/assets/images/icons/arrow-long.svg" alt="Назад"> Назад
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'HeaderAuth',
  props: {
    back: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logo() {
      return require(this.$store.getters['auth/isTA'] ? '@/assets/images/logoTA.svg' : '@/assets/images/logo.svg');
    },

  }
};
</script>

<style scoped lang="scss">
.header {
  padding: 2rem;
}
</style>
