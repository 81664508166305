var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"value":_vm.progress.active,"persistent":"","width":"500"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticStyle:{"color":"#fff"}},[_vm._v(" Пожалуйста подождите, идет экспорт... "),_c('v-progress-linear',{staticClass:"mt-4",staticStyle:{"color":"#25325F"},attrs:{"color":"white","value":_vm.progress.cur/_vm.progress.total*100-5,"height":"35"}},[_vm._v(" "+_vm._s(_vm.progress.cur-_vm.progress.blockSize+1 > 0 ? _vm.progress.cur-_vm.progress.blockSize+1 : 0)+" - "+_vm._s(_vm.progress.cur)+" из "+_vm._s(_vm.progress.total)+" кандидатов ")])],1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',_vm._b({staticClass:"mr-1 icon px-0",attrs:{"color":"primary","outlined":"","disabled":!_vm.active,"loading":_vm.loading.loadResults},on:{"click":_vm.exportToTable}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":_vm.active ? 'primary' : '#9BA1B5'}},[_vm._v("mdi-upload")])],1)],1)]}}])},[_c('span',[_vm._v("Выгрузить в Excel")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',_vm._b({staticClass:"mr-4 icon px-0",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$store.commit('notifications/setShowPanelColumns', 'ExcelColumns2')}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-table-column-remove")])],1)],1)]}}])},[_c('span',[_vm._v("Настроить выгрузку")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[(_vm.$store.getters['auth/isAllowed']('download_competence_report'))?_c('v-btn',_vm._b({staticClass:"mr-4 icon px-0",attrs:{"color":"primary","outlined":"","disabled":!_vm.active,"loading":_vm.loading.loadResults},on:{"click":_vm.exportToTableCompetences}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":_vm.active ? 'primary' : '#9BA1B5'}},[_vm._v("mdi-upload-multiple")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Выгрузить в Excel отчет по компетенциям")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[(_vm.$store.getters['auth/isAllowed']('delete_candidate'))?_c('v-btn',_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"disabled":!_vm.active,"color":"primary","outlined":""},on:{"click":function($event){_vm.dialogDeleteShow = true}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":_vm.active ? 'primary' : '#9BA1B5'}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Удалить")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[(_vm.$store.getters['auth/isAllowed']('edit_candidate'))?_c('v-btn',_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":"","disabled":!_vm.active,"loading":_vm.loading.active},on:{"click":function($event){return _vm.inactiveCandidates()}}},'v-btn',attrs,false),[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715\n              22 12ZM15.7121 8.78787L17.2121 10.2879L17.4243 10.5L17.2121 10.7121L15.7121 12.2121L15.2879 11.7879L16.2757 10.8H7V10.2H16.2757L15.2879 9.21213L15.7121 8.78787ZM6.78787\n              13.7121L8.28787 15.2121L8.71213 14.7879L7.72426 13.8H17V13.2H7.72426L8.71213 12.2121L8.28787 11.7879L6.78787 13.2879L6.57574 13.5L6.78787 13.7121Z","fill":_vm.active ? 'var(--v-primary-base)' : '#9BA1B5'}})])]):_vm._e()],1)]}}])},[_vm._v(" Сделать неактивными ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[(_vm.$store.getters['auth/isAllowed']('view_testresultscompliencereport'))?_c('v-btn',_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":"","disabled":_vm.isFilterEmpty},on:{"click":function($event){return _vm.clearFilterAll()}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":_vm.isFilterEmpty ? '#9BA1B5' : 'primary'}},[_vm._v("mdi-filter-remove-outline")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Сбросить все фильтры")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',_vm._b({staticClass:"px-0 mr-4 icon",attrs:{"color":"primary","outlined":"","disabled":!_vm.active},on:{"click":function($event){_vm.showCalcComplianceDialog=true}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-calculator")])],1)],1)]}}])},[_c('span',[_vm._v("Расчитать соответствие")])]),_c('SelectProfgrams',{model:{value:(_vm.showCalcComplianceDialog),callback:function ($$v) {_vm.showCalcComplianceDialog=$$v},expression:"showCalcComplianceDialog"}}),_c('DialogDelete',{model:{value:(_vm.dialogDeleteShow),callback:function ($$v) {_vm.dialogDeleteShow=$$v},expression:"dialogDeleteShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }