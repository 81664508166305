import { render, staticRenderFns } from "./Start.vue?vue&type=template&id=8f0a8f08&scoped=true"
import script from "./Start.vue?vue&type=script&lang=js"
export * from "./Start.vue?vue&type=script&lang=js"
import style0 from "./Start.vue?vue&type=style&index=0&id=8f0a8f08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f0a8f08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
