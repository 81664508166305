<template>
  <v-app>
    <v-navigation-drawer app :value="drawer" @input="setDrawer" clipped>

      <v-list dense nav class="mt-5">

        <div v-for="(item, ind) in itemsMenu" :key="ind">
          <v-list-item v-if="!item.children" class="_my-1 ma-2" color="primary" :to="{name: item.to}">
            <template #default="{active}">
              <div class="mr-2">
                <div class="icon-menu" >
                  <div v-if="item.icon" v-html="item.icon" class="pt-2" />
                  <img v-else width="50px" :src="require(`@/assets/images/menu/${item.img ? item.img : 'account'}${active ? '-active' : ''}.svg`)" />
                </div>
              </div>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

          <!-- <v-list-group v-else-if="permittedChildren(item).length" :value="false">
            <template v-slot:activator>
              <div class="mr-5">
                <div class="icon-menu" >
                  <img :src="require(`@/assets/images/menu/${item.img ? item.img : 'account'}${active ? '-active' : ''}.svg`)" />
                </div>
              </div>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item v-for="(child, child_ind) in permittedChildren(item)" :key="child_ind"
              color="primary" :to="{name: child.to}"
            >
              <div v-if="child.icon" class="ml-5 mr-5">
                <v-icon>{{ child.icon }}</v-icon>
              </div>
              <v-list-item-content :class="{'ml-15': !child.icon}">
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> -->
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <button type="button" fab class="v-app-bar__nav-icon pl-0 theme--light v-size--default" @click="setDrawer(!drawer)">
        <img width="50px" src="@/assets/images/menu/menu.svg">
      </button>
      <!-- <button type="button" class="v-app-bar__nav-icon pl-0 v-btn theme--light v-size--default" @click="setDrawer(!drawer)">
          <span class="v-btn__content"><i class="v-icon notranslate mdi mdi-menu theme--light mr-1"></i><b>МЕНЮ</b></span>
      </button> -->
      <!-- <v-spacer /> -->
      <v-toolbar-title>
        <router-link :to="{name: 'home'}"><img class="ml-3 mt-1" :src="logo" alt="Главная"></router-link>
      </v-toolbar-title>

      <v-spacer />

      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1688 14.5158H9.07708C7.71042 14.5158 6.60208 13.3658 6.60208 11.9491C6.60208 11.6074 6.88542 11.3241 7.22708 11.3241C7.56875 11.3241 7.85208 11.6074 7.85208 11.9491C7.85208 12.6741 8.40208 13.2658 9.07708 13.2658H11.1688C11.7104 13.2658 12.1604 12.7824 12.1604 12.1991C12.1604 11.4741 11.9021 11.3324 11.4771 11.1824L8.11875 9.99909C7.46875 9.77409 6.59375 9.29075 6.59375 7.79909C6.59375 6.51575 7.60208 5.48242 8.83542 5.48242H10.9271C12.2938 5.48242 13.4021 6.63242 13.4021 8.04909C13.4021 8.39075 13.1188 8.67409 12.7771 8.67409C12.4354 8.67409 12.1521 8.39075 12.1521 8.04909C12.1521 7.32409 11.6021 6.73242 10.9271 6.73242H8.83542C8.29375 6.73242 7.84375 7.21575 7.84375 7.79909C7.84375 8.52409 8.10208 8.66575 8.52708 8.81575L11.8854 9.99909C12.5354 10.2241 13.4104 10.7074 13.4104 12.1991C13.4021 13.4741 12.4021 14.5158 11.1688 14.5158Z" fill="var(--v-primary-base)"/>
        <path d="M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z" fill="var(--v-primary-base)"/>
        <path d="M9.99739 18.9577C5.05573 18.9577 1.03906 14.941 1.03906 9.99935C1.03906 5.05768 5.05573 1.04102 9.99739 1.04102C14.9391 1.04102 18.9557 5.05768 18.9557 9.99935C18.9557 14.941 14.9391 18.9577 9.99739 18.9577ZM9.99739 2.29102C5.7474 2.29102 2.28906 5.74935 2.28906 9.99935C2.28906 14.2493 5.7474 17.7077 9.99739 17.7077C14.2474 17.7077 17.7057 14.2493 17.7057 9.99935C17.7057 5.74935 14.2474 2.29102 9.99739 2.29102Z" fill="var(--v-primary-base)"/>
      </svg>

      <div class="mx-2 profile-info">
        Баланс тестов:
        <!-- <v-progress-circular v-if="balanceLoading.stock_balance" :size="20" color="primary" indeterminate/> -->
        <span _v-else>{{ user? user.licenses_available : ''}}</span>
      </div>

      <div class="mx-2 profile-info">
        {{ user ? user.last_name + ' ' + user.first_name : '' }}
      </div>
      <v-btn icon @click="onLogout" :loading="loading" :disabled="loading">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="bg-main h-100">
      <router-view />
    </v-main>

    <v-menu offset-y top >
      <template v-slot:activator="{ on, attrs }">
        <v-btn fixed right bottom fab dark small color="primary" v-bind="attrs" v-on="on">
          <v-icon dark>mdi-help</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <a href="/support" class="text-dark-gray">Техническая поддержка</a>
        </v-list-item>
        <v-list-item>
          <a href="https://vkondrashin.notion.site/SOSTAFF-io-5b036c0b811d4a309500cd6c47794cb4" target="_blank" class="text-dark-gray">База знаний</a>
        </v-list-item>
      </v-list>
    </v-menu>

    <ExportColumnsPanel :columns="ColumnsSetupLong.columns" :columnsKey="ColumnsSetupLong.columnsKey"/>
    <ExportColumnsPanel :columns="ColumnsSetupShort.columns" :columnsKey="ColumnsSetupShort.columnsKey"/>

  </v-app>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';
import ExportColumnsPanel from '@/components/candidates/table/ExportColumnsPanel';
import * as ColumnsSetupLong from '@/common/exportExcel/columnsCandidats';
import * as ColumnsSetupShort from '@/common/exportExcel/columnsCandidatsShort';

export default {
  name: 'DashboardLayout',
  components: {
    ExportColumnsPanel
  },
  data: () => ({
    drawer: true,
    items: [
      {
        title: 'Личный кабинет',
        img: 'account',
        to: 'profile',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1302 9.68366C10.1052 9.68366 10.0885 9.68366 10.0635 9.68366C10.0219 9.67533 9.96354 9.67533 9.91354 9.68366C7.49687 9.60866 5.67188 7.70866 5.67188 5.36699C5.67188 2.98366 7.61354 1.04199 9.99688 1.04199C12.3802 1.04199 14.3219 2.98366 14.3219 5.36699C14.3135 7.70866 12.4802 9.60866 10.1552 9.68366C10.1469 9.68366 10.1385 9.68366 10.1302 9.68366ZM9.99688 2.29199C8.30521 2.29199 6.92188 3.67533 6.92188 5.36699C6.92188 7.03366 8.22187 8.37533 9.88021 8.43366C9.92187 8.42533 10.0385 8.42533 10.1469 8.43366C11.7802 8.35866 13.0635 7.01699 13.0719 5.36699C13.0719 3.67533 11.6885 2.29199 9.99688 2.29199Z" fill="#25325F"/>
          <path d="M10.1443 18.7913C8.51094 18.7913 6.86927 18.3747 5.6276 17.5413C4.46927 16.7747 3.83594 15.7247 3.83594 14.583C3.83594 13.4413 4.46927 12.383 5.6276 11.608C8.1276 9.94967 12.1776 9.94967 14.6609 11.608C15.8109 12.3747 16.4526 13.4247 16.4526 14.5663C16.4526 15.708 15.8193 16.7663 14.6609 17.5413C13.4109 18.3747 11.7776 18.7913 10.1443 18.7913ZM6.31927 12.658C5.51927 13.1913 5.08594 13.8747 5.08594 14.5913C5.08594 15.2997 5.5276 15.983 6.31927 16.508C8.39427 17.8997 11.8943 17.8997 13.9693 16.508C14.7693 15.9747 15.2026 15.2913 15.2026 14.5747C15.2026 13.8663 14.7609 13.183 13.9693 12.658C11.8943 11.2747 8.39427 11.2747 6.31927 12.658Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_personal_cabinet'],
      },
      {
        title: 'Отчеты',
        to: 'reports',
        img: 'report',
        permission: ['bookmark_reports'],
      },
      // children: [
      //   {
      //     title: 'Полный отчет',
      //     to: 'fulltest',
      //     permission: ['allow_fullreport_inherit'],
      //   },
      //   {
      //     title: 'Краткий отчет',
      //     to: 'shorttest',
      //     permission: ['allow_shortreport'],
      //   },
      //   {
      //     title: 'Отчет респондента',
      //     to: 'psychotype',
      //     permission: ['allow_psychotypereport_inherit'],
      //   },
      // ],
      // },
      {
        title: 'Менеджеры',
        img: 'managers',
        to: 'managers',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="User / Users">
          <path id="Vector" d="M11.6693 13.0562L10.0026 10.834L8.33594 13.0562L10.0027 15.834L11.6693 13.0562Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path id="Vector_2" d="M9.9974 9.16667C11.8383 9.16667 13.3307 7.67428 13.3307 5.83333C13.3307 3.99238 11.8383 2.5 9.9974 2.5C8.15645 2.5 6.66406 3.99238 6.66406 5.83333C6.66406 7.67428 8.15645 9.16667 9.9974 9.16667Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path id="Vector_3" d="M4.16406 15C4.16406 14.0044 4.92787 13.1108 6.1389 12.5M15.8307 15C15.8307 14.0044 15.0669 13.1108 13.8559 12.5" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          </svg>`,
        permission: ['bookmark_managers'],
      },
      {
        title: 'Корпоративные клиенты',
        to: 'clients',
        img: 'clients',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.63021 9.68366C7.60521 9.68366 7.58854 9.68366 7.56354 9.68366C7.52188 9.67533 7.46354 9.67533 7.41354 9.68366C4.99687 9.60866 3.17188 7.70866 3.17188 5.36699C3.17188 2.98366 5.11354 1.04199 7.49688 1.04199C9.88021 1.04199 11.8219 2.98366 11.8219 5.36699C11.8135 7.70866 9.98021 9.60866 7.65521 9.68366C7.64687 9.68366 7.63854 9.68366 7.63021 9.68366ZM7.49688 2.29199C5.80521 2.29199 4.42188 3.67533 4.42188 5.36699C4.42188 7.03366 5.72187 8.37533 7.38021 8.43366C7.43021 8.42533 7.53854 8.42533 7.64688 8.43366C9.28021 8.35866 10.5635 7.01699 10.5719 5.36699C10.5719 3.67533 9.18854 2.29199 7.49688 2.29199Z" fill="#25325F"/>
          <path d="M13.78 9.79134C13.755 9.79134 13.73 9.79134 13.705 9.78301C13.3634 9.81634 13.0134 9.57468 12.98 9.23301C12.9467 8.89134 13.155 8.58301 13.4967 8.54134C13.5967 8.53301 13.705 8.53301 13.7967 8.53301C15.0134 8.46634 15.9634 7.46634 15.9634 6.24134C15.9634 4.97467 14.9384 3.94967 13.6717 3.94967C13.33 3.95801 13.0467 3.67467 13.0467 3.33301C13.0467 2.99134 13.33 2.70801 13.6717 2.70801C15.6217 2.70801 17.2134 4.29967 17.2134 6.24967C17.2134 8.16634 15.7134 9.71634 13.805 9.79134C13.7967 9.79134 13.7884 9.79134 13.78 9.79134Z" fill="#25325F"/>
          <path d="M7.64427 18.7913C6.01094 18.7913 4.36927 18.3747 3.1276 17.5413C1.96927 16.7747 1.33594 15.7247 1.33594 14.583C1.33594 13.4413 1.96927 12.383 3.1276 11.608C5.6276 9.94967 9.6776 9.94967 12.1609 11.608C13.3109 12.3747 13.9526 13.4247 13.9526 14.5663C13.9526 15.708 13.3193 16.7663 12.1609 17.5413C10.9109 18.3747 9.2776 18.7913 7.64427 18.7913ZM3.81927 12.658C3.01927 13.1913 2.58594 13.8747 2.58594 14.5913C2.58594 15.2997 3.0276 15.983 3.81927 16.508C5.89427 17.8997 9.39427 17.8997 11.4693 16.508C12.2693 15.9747 12.7026 15.2913 12.7026 14.5747C12.7026 13.8663 12.2609 13.183 11.4693 12.658C9.39427 11.2747 5.89427 11.2747 3.81927 12.658Z" fill="#25325F"/>
          <path d="M15.2844 17.2917C14.9928 17.2917 14.7344 17.0917 14.6761 16.7917C14.6094 16.45 14.8261 16.125 15.1594 16.05C15.6844 15.9417 16.1678 15.7333 16.5428 15.4417C17.0178 15.0833 17.2761 14.6333 17.2761 14.1583C17.2761 13.6833 17.0178 13.2333 16.5511 12.8833C16.1844 12.6 15.7261 12.4 15.1844 12.275C14.8511 12.2 14.6344 11.8667 14.7094 11.525C14.7844 11.1917 15.1178 10.975 15.4594 11.05C16.1761 11.2083 16.8011 11.4917 17.3094 11.8833C18.0844 12.4667 18.5261 13.2917 18.5261 14.1583C18.5261 15.025 18.0761 15.85 17.3011 16.4417C16.7844 16.8417 16.1344 17.1333 15.4178 17.275C15.3678 17.2917 15.3261 17.2917 15.2844 17.2917Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_corporate_clients'],
      },
      {
        title: 'Реферальная программа',
        to: 'refprogram',
        img: 'ref',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3047 18.958H6.63802C3.78802 18.958 2.67969 17.8497 2.67969 14.9997V8.33301C2.67969 7.99134 2.96302 7.70801 3.30469 7.70801H16.638C16.9797 7.70801 17.263 7.99134 17.263 8.33301V14.9997C17.263 17.8497 16.1547 18.958 13.3047 18.958ZM3.92969 8.95801V14.9997C3.92969 17.1497 4.48802 17.708 6.63802 17.708H13.3047C15.4547 17.708 16.013 17.1497 16.013 14.9997V8.95801H3.92969Z" fill="#25325F"/>
          <path d="M16.2526 8.95866H3.7526C2.29427 8.95866 1.46094 8.12533 1.46094 6.66699V5.83366C1.46094 4.37533 2.29427 3.54199 3.7526 3.54199H16.2526C17.6693 3.54199 18.5443 4.41699 18.5443 5.83366V6.66699C18.5443 8.08366 17.6693 8.95866 16.2526 8.95866ZM3.7526 4.79199C2.99427 4.79199 2.71094 5.07533 2.71094 5.83366V6.66699C2.71094 7.42533 2.99427 7.70866 3.7526 7.70866H16.2526C16.9859 7.70866 17.2943 7.40033 17.2943 6.66699V5.83366C17.2943 5.10033 16.9859 4.79199 16.2526 4.79199H3.7526Z" fill="#25325F"/>
          <path d="M9.70187 4.79173H5.10187C4.92687 4.79173 4.7602 4.71673 4.64354 4.59173C4.1352 4.0334 4.15187 3.17507 4.6852 2.64173L5.86854 1.4584C6.41854 0.908398 7.32687 0.908398 7.87687 1.4584L10.1435 3.72506C10.3185 3.90006 10.3769 4.17506 10.2769 4.4084C10.1852 4.64173 9.9602 4.79173 9.70187 4.79173ZM5.5602 3.54173H8.20187L6.99354 2.34173C6.92687 2.27507 6.81854 2.27507 6.75187 2.34173L5.56854 3.52507C5.56854 3.5334 5.5602 3.5334 5.5602 3.54173Z" fill="#25325F"/>
          <path d="M14.8878 4.79173H10.2878C10.0378 4.79173 9.8045 4.64173 9.71283 4.4084C9.61283 4.17506 9.67117 3.9084 9.84617 3.72506L12.1128 1.4584C12.6628 0.908398 13.5712 0.908398 14.1212 1.4584L15.3045 2.64173C15.8378 3.17507 15.8628 4.0334 15.3462 4.59173C15.2295 4.71673 15.0628 4.79173 14.8878 4.79173ZM11.8045 3.54173H14.4462C14.4378 3.5334 14.4378 3.5334 14.4295 3.52507L13.2462 2.34173C13.1795 2.27507 13.0712 2.27507 13.0045 2.34173L11.8045 3.54173Z" fill="#25325F"/>
          <path d="M8.28646 14.083C8.05312 14.083 7.81146 14.0247 7.59479 13.908C7.11979 13.6497 6.82812 13.158 6.82812 12.6247V8.33301C6.82812 7.99134 7.11146 7.70801 7.45312 7.70801H12.4865C12.8281 7.70801 13.1115 7.99134 13.1115 8.33301V12.608C13.1115 13.1497 12.8198 13.6413 12.3448 13.8913C11.8698 14.1497 11.2948 14.1163 10.8448 13.8163L10.1031 13.3163C10.0365 13.2663 9.94479 13.2663 9.86979 13.3163L9.08646 13.833C8.84479 13.9997 8.56146 14.083 8.28646 14.083ZM8.07812 8.95801V12.6163C8.07812 12.7247 8.14479 12.7747 8.18646 12.7997C8.22812 12.8247 8.31146 12.8497 8.40313 12.7913L9.18646 12.2747C9.67813 11.9497 10.3115 11.9497 10.7948 12.2747L11.5365 12.7747C11.6281 12.833 11.7115 12.808 11.7531 12.783C11.7948 12.758 11.8615 12.708 11.8615 12.5997V8.94967H8.07812V8.95801Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_corporate_clients'],
      },
      {
        title: 'Кандидаты',
        to: 'candidates',
        img: 'candidates',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.999 6.59148C14.974 6.59148 14.9573 6.59148 14.9323 6.59148H14.8906C13.3156 6.54148 12.1406 5.32481 12.1406 3.82481C12.1406 2.29147 13.3906 1.0498 14.9156 1.0498C16.4406 1.0498 17.6906 2.29981 17.6906 3.82481C17.6823 5.33314 16.5073 6.54981 15.0073 6.59981C15.0073 6.59147 15.0073 6.59148 14.999 6.59148ZM14.9156 2.29148C14.074 2.29148 13.3906 2.97481 13.3906 3.81648C13.3906 4.64148 14.0323 5.30815 14.8573 5.34148C14.8656 5.33315 14.9323 5.33315 15.0073 5.34148C15.8156 5.29981 16.4406 4.63314 16.449 3.81648C16.449 2.97481 15.7656 2.29148 14.9156 2.29148Z" fill="#25325F"/>
          <path d="M15.0097 12.7329C14.6847 12.7329 14.3597 12.7079 14.0347 12.6495C13.6931 12.5912 13.4681 12.2662 13.5264 11.9246C13.5847 11.5829 13.9097 11.3579 14.2514 11.4162C15.2764 11.5912 16.3597 11.3996 17.0847 10.9162C17.4764 10.6579 17.6847 10.3329 17.6847 10.0079C17.6847 9.68288 17.4681 9.36622 17.0847 9.10788C16.3597 8.62455 15.2597 8.43289 14.2264 8.61622C13.8847 8.68289 13.5597 8.44955 13.5014 8.10789C13.4431 7.76622 13.6681 7.44122 14.0097 7.38289C15.3681 7.14122 16.7764 7.39955 17.7764 8.06622C18.5097 8.55788 18.9347 9.25788 18.9347 10.0079C18.9347 10.7496 18.5181 11.4579 17.7764 11.9579C17.0181 12.4579 16.0347 12.7329 15.0097 12.7329Z" fill="#25325F"/>
          <path d="M4.97344 6.592C4.96511 6.592 4.95677 6.592 4.95677 6.592C3.45677 6.542 2.28177 5.32532 2.27344 3.82532C2.27344 2.29199 3.52344 1.04199 5.04844 1.04199C6.57344 1.04199 7.82344 2.29199 7.82344 3.81699C7.82344 5.32533 6.64844 6.542 5.14844 6.592L4.97344 5.967L5.03177 6.592C5.01511 6.592 4.99011 6.592 4.97344 6.592ZM5.05677 5.342C5.10677 5.342 5.14844 5.34199 5.19844 5.35032C5.94011 5.31699 6.5901 4.65032 6.5901 3.82532C6.5901 2.98366 5.90677 2.30032 5.06511 2.30032C4.22344 2.30032 3.5401 2.98366 3.5401 3.82532C3.5401 4.64199 4.17344 5.30032 4.98177 5.35032C4.9901 5.34199 5.02344 5.342 5.05677 5.342Z" fill="#25325F"/>
          <path d="M4.96406 12.7329C3.93906 12.7329 2.95573 12.4579 2.1974 11.9579C1.46406 11.4662 1.03906 10.7579 1.03906 10.0079C1.03906 9.26622 1.46406 8.55788 2.1974 8.06622C3.1974 7.39955 4.60573 7.14122 5.96406 7.38289C6.30573 7.44122 6.53073 7.76622 6.4724 8.10789C6.41406 8.44955 6.08906 8.68289 5.7474 8.61622C4.71406 8.43289 3.6224 8.62455 2.88906 9.10788C2.4974 9.36622 2.28906 9.68288 2.28906 10.0079C2.28906 10.3329 2.50573 10.6579 2.88906 10.9162C3.61406 11.3996 4.6974 11.5912 5.7224 11.4162C6.06406 11.3579 6.38906 11.5912 6.4474 11.9246C6.50573 12.2662 6.28073 12.5912 5.93906 12.6495C5.61406 12.7079 5.28906 12.7329 4.96406 12.7329Z" fill="#25325F"/>
          <path d="M9.99896 12.8171C9.97396 12.8171 9.95729 12.8171 9.93229 12.8171H9.89062C8.31562 12.7671 7.14062 11.5504 7.14062 10.0504C7.14062 8.51706 8.39063 7.27539 9.91563 7.27539C11.4406 7.27539 12.6906 8.52539 12.6906 10.0504C12.6823 11.5587 11.5073 12.7754 10.0073 12.8254C10.0073 12.8171 10.0073 12.8171 9.99896 12.8171ZM9.91563 8.51706C9.07396 8.51706 8.39062 9.2004 8.39062 10.0421C8.39062 10.8671 9.03229 11.5337 9.85729 11.5671C9.86563 11.5587 9.93229 11.5587 10.0073 11.5671C10.8156 11.5254 11.4406 10.8587 11.449 10.0421C11.449 9.20873 10.7656 8.51706 9.91563 8.51706Z" fill="#25325F"/>
          <path d="M10.0036 18.9666C9.00365 18.9666 8.00365 18.7083 7.22865 18.1833C6.49531 17.6916 6.07031 16.9916 6.07031 16.2416C6.07031 15.4999 6.48698 14.7833 7.22865 14.2916C8.78698 13.2583 11.2286 13.2583 12.7786 14.2916C13.512 14.7833 13.937 15.4833 13.937 16.2333C13.937 16.9749 13.5203 17.6916 12.7786 18.1833C12.0036 18.6999 11.0036 18.9666 10.0036 18.9666ZM7.92031 15.3416C7.52865 15.5999 7.32031 15.9249 7.32031 16.2499C7.32031 16.5749 7.53698 16.8916 7.92031 17.1499C9.04531 17.9083 10.9536 17.9083 12.0786 17.1499C12.4703 16.8916 12.6786 16.5666 12.6786 16.2416C12.6786 15.9166 12.462 15.5999 12.0786 15.3416C10.962 14.5833 9.05365 14.5916 7.92031 15.3416Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_candidates'],
      },
      {
        title: 'Профили должностей',
        to: 'profgrams',
        img: 'profgrams',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3352 18.9588H6.66848C2.81848 18.9588 2.10182 17.1671 1.91848 15.4255L1.29348 8.75046C1.20182 7.87546 1.17682 6.58379 2.04348 5.61712C2.79348 4.78379 4.03515 4.38379 5.83515 4.38379H14.1685C15.9768 4.38379 17.2185 4.79212 17.9602 5.61712C18.8268 6.58379 18.8018 7.87546 18.7102 8.75879L18.0852 15.4171C17.9018 17.1671 17.1852 18.9588 13.3352 18.9588ZM5.83515 5.62546C4.42682 5.62546 3.46015 5.90046 2.96848 6.45046C2.56015 6.90046 2.42682 7.59212 2.53515 8.62546L3.16015 15.3005C3.30182 16.6171 3.66015 17.7088 6.66848 17.7088H13.3352C16.3352 17.7088 16.7018 16.6171 16.8435 15.2921L17.4685 8.63379C17.5768 7.59212 17.4435 6.90046 17.0352 6.45046C16.5435 5.90046 15.5768 5.62546 14.1685 5.62546H5.83515Z" fill="#25325F"/>
          <path d="M13.3307 5.62533C12.9891 5.62533 12.7057 5.34199 12.7057 5.00033V4.33366C12.7057 2.85033 12.7057 2.29199 10.6641 2.29199H9.33073C7.28906 2.29199 7.28906 2.85033 7.28906 4.33366V5.00033C7.28906 5.34199 7.00573 5.62533 6.66406 5.62533C6.3224 5.62533 6.03906 5.34199 6.03906 5.00033V4.33366C6.03906 2.86699 6.03906 1.04199 9.33073 1.04199H10.6641C13.9557 1.04199 13.9557 2.86699 13.9557 4.33366V5.00033C13.9557 5.34199 13.6724 5.62533 13.3307 5.62533Z" fill="#25325F"/>
          <path d="M10.0026 13.9583C7.71094 13.9583 7.71094 12.5417 7.71094 11.6917V10.8333C7.71094 9.65833 7.99427 9.375 9.16927 9.375H10.8359C12.0109 9.375 12.2943 9.65833 12.2943 10.8333V11.6667C12.2943 12.5333 12.2943 13.9583 10.0026 13.9583ZM8.96094 10.625C8.96094 10.6917 8.96094 10.7667 8.96094 10.8333V11.6917C8.96094 12.55 8.96094 12.7083 10.0026 12.7083C11.0443 12.7083 11.0443 12.575 11.0443 11.6833V10.8333C11.0443 10.7667 11.0443 10.6917 11.0443 10.625C10.9776 10.625 10.9026 10.625 10.8359 10.625H9.16927C9.1026 10.625 9.0276 10.625 8.96094 10.625Z" fill="#25325F"/>
          <path d="M11.6682 12.3079C11.3599 12.3079 11.0849 12.0745 11.0515 11.7579C11.0099 11.4162 11.2515 11.0995 11.5932 11.0579C13.7932 10.7829 15.9015 9.94955 17.6765 8.65788C17.9515 8.44955 18.3432 8.51622 18.5515 8.79955C18.7515 9.07455 18.6932 9.46622 18.4099 9.67455C16.4599 11.0912 14.1599 11.9995 11.7432 12.3079C11.7182 12.3079 11.6932 12.3079 11.6682 12.3079Z" fill="#25325F"/>
          <path d="M8.33589 12.3164C8.31089 12.3164 8.28589 12.3164 8.26089 12.3164C5.97756 12.0581 3.75256 11.2248 1.82756 9.90809C1.54422 9.71642 1.46922 9.32476 1.66089 9.04142C1.85256 8.75809 2.24422 8.68309 2.52756 8.87476C4.28589 10.0748 6.31089 10.8331 8.39422 11.0748C8.73589 11.1164 8.98589 11.4248 8.94422 11.7664C8.91922 12.0831 8.65256 12.3164 8.33589 12.3164Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_positions_profiles'],
      },
      {
        title: 'Аналитика',
        to: 'analitics',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-inside-1_1886_4191" fill="white">
          <path d="M15.0833 14.792H4.91666C2.98333 14.792 1.875 13.6837 1.875 11.7503V1.66699C1.875 1.32533 2.15833 1.04199 2.5 1.04199H17.5C17.8417 1.04199 18.125 1.32533 18.125 1.66699V11.7503C18.125 13.6837 17.0167 14.792 15.0833 14.792ZM3.125 2.29199V11.7503C3.125 12.992 3.67499 13.542 4.91666 13.542H15.075C16.3167 13.542 16.8667 12.992 16.8667 11.7503V2.29199H3.125Z"/>
          </mask>
          <path d="M3.125 2.29199V0.991992H1.825V2.29199H3.125ZM16.8667 2.29199H18.1667V0.991992H16.8667V2.29199ZM15.0833 13.492H4.91666V16.092H15.0833V13.492ZM4.91666 13.492C4.19557 13.492 3.80284 13.2897 3.59007 13.0769C3.3773 12.8642 3.175 12.4714 3.175 11.7503H0.575C0.575 12.9626 0.926866 14.0907 1.75159 14.9154C2.57632 15.7401 3.70442 16.092 4.91666 16.092V13.492ZM3.175 11.7503V1.66699H0.575V11.7503H3.175ZM3.175 1.66699C3.175 2.0433 2.8763 2.34199 2.5 2.34199V-0.258008C1.44036 -0.258008 0.575 0.607355 0.575 1.66699H3.175ZM2.5 2.34199H17.5V-0.258008H2.5V2.34199ZM17.5 2.34199C17.1237 2.34199 16.825 2.0433 16.825 1.66699H19.425C19.425 0.607355 18.5596 -0.258008 17.5 -0.258008V2.34199ZM16.825 1.66699V11.7503H19.425V1.66699H16.825ZM16.825 11.7503C16.825 12.4714 16.6227 12.8642 16.4099 13.0769C16.1972 13.2897 15.8044 13.492 15.0833 13.492V16.092C16.2956 16.092 17.4237 15.7401 18.2484 14.9154C19.0731 14.0907 19.425 12.9626 19.425 11.7503H16.825ZM1.825 2.29199V11.7503H4.425V2.29199H1.825ZM1.825 11.7503C1.825 12.532 1.99457 13.3896 2.63597 14.031C3.27737 14.6724 4.13498 14.842 4.91666 14.842V12.242C4.45668 12.242 4.41846 12.1366 4.47444 12.1925C4.53043 12.2485 4.425 12.2103 4.425 11.7503H1.825ZM4.91666 14.842H15.075V12.242H4.91666V14.842ZM15.075 14.842C15.8567 14.842 16.7143 14.6724 17.3557 14.031C17.9971 13.3896 18.1667 12.532 18.1667 11.7503H15.5667C15.5667 12.2103 15.4612 12.2485 15.5172 12.1925C15.5732 12.1366 15.535 12.242 15.075 12.242V14.842ZM18.1667 11.7503V2.29199H15.5667V11.7503H18.1667ZM16.8667 0.991992H3.125V3.59199H16.8667V0.991992Z" fill="#25325F" mask="url(#path-1-inside-1_1886_4191)"/>
          <mask id="path-3-inside-2_1886_4191" fill="white">
          <path d="M18.3307 2.29199H1.66406C1.3224 2.29199 1.03906 2.00866 1.03906 1.66699C1.03906 1.32533 1.3224 1.04199 1.66406 1.04199H18.3307C18.6724 1.04199 18.9557 1.32533 18.9557 1.66699C18.9557 2.00866 18.6724 2.29199 18.3307 2.29199Z"/>
          </mask>
          <path d="M18.3307 0.991992H1.66406V3.59199H18.3307V0.991992ZM1.66406 0.991992C2.04037 0.991992 2.33906 1.29069 2.33906 1.66699H-0.260937C-0.260937 2.72663 0.604426 3.59199 1.66406 3.59199V0.991992ZM2.33906 1.66699C2.33906 2.0433 2.04037 2.34199 1.66406 2.34199V-0.258008C0.604426 -0.258008 -0.260937 0.607355 -0.260937 1.66699H2.33906ZM1.66406 2.34199H18.3307V-0.258008H1.66406V2.34199ZM18.3307 2.34199C17.9544 2.34199 17.6557 2.0433 17.6557 1.66699H20.2557C20.2557 0.607355 19.3904 -0.258008 18.3307 -0.258008V2.34199ZM17.6557 1.66699C17.6557 1.29069 17.9544 0.991992 18.3307 0.991992V3.59199C19.3904 3.59199 20.2557 2.72663 20.2557 1.66699H17.6557Z" fill="#25325F" mask="url(#path-3-inside-2_1886_4191)"/>
          <path d="M6.66591 18.3253L6.65773 18.3089L6.67092 18.3353L6.66789 18.3293M6.66591 18.3253L6.66789 18.3293M6.66591 18.3253L6.66789 18.3293M6.66591 18.3253L6.66789 18.3293M6.39137 17.7762L6.65691 18.3073L6.39137 17.7762Z" stroke="#25325F" stroke-width="1.3"/>
          <path d="M6.24619 9.79157C6.07119 9.79157 5.88787 9.71658 5.76287 9.56658C5.53787 9.29991 5.57952 8.90824 5.84619 8.68324L8.47121 6.49157C8.71287 6.29157 9.02119 6.20824 9.31286 6.25824C9.61286 6.30824 9.8712 6.49155 10.0295 6.75822L10.9045 8.21655L13.3462 6.18324C13.6129 5.96658 14.0045 5.99989 14.2295 6.26656C14.4545 6.53322 14.4129 6.92489 14.1462 7.14989L11.5212 9.34156C11.2795 9.54156 10.9712 9.62489 10.6795 9.57489C10.3795 9.52489 10.1212 9.34158 9.96285 9.07491L9.08786 7.61658L6.6462 9.64989C6.52954 9.74155 6.38786 9.79157 6.24619 9.79157Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_analytics'],
      },
      {
        title: 'Партнеры',
        to: 'partners',
        icon: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.09766 11.5549L4.76047 9.68282C4.30586 9.04636 4.37801 8.17453 4.93107 7.62147L6.74997 5.80257C7.34115 5.21139 8.28783 5.17503 8.92261 5.71913L10.548 7.11229C10.693 7.23657 10.8776 7.30488 11.0686 7.30488H13.7977C14.3013 7.30488 14.7755 7.54199 15.0777 7.94488L15.8727 9.00488M6.09766 11.5549H11.6727C12.1145 11.5549 12.4727 11.9131 12.4727 12.3549V12.5049C12.4727 13.3885 13.189 14.1049 14.0727 14.1049H14.2727C15.1563 14.1049 15.8727 13.3885 15.8727 12.5049V9.00488M6.09766 11.5549H5.19766C4.75583 11.5549 4.39766 11.9131 4.39766 12.3549V13.3549C4.39766 14.2385 5.114 14.9549 5.99766 14.9549H6.52266V15.0549C6.52266 15.9385 7.239 16.6549 8.12266 16.6549H8.64766V16.7549C8.64766 17.6385 9.364 18.3549 10.2477 18.3549H13.3227L15.5913 20.6235C16.2161 21.2484 17.2292 21.2484 17.854 20.6235L19.8413 18.6363C20.4661 18.0114 20.4661 16.9984 19.8413 16.3735L19.507 16.0392C19.3569 15.8892 19.2727 15.6857 19.2727 15.4735V12.7363C19.2727 12.5241 19.1884 12.3206 19.0383 12.1706L15.8727 9.00488" stroke="#2E3A59" stroke-width="1.6"/>
          </svg>`,
        permission: ['bookmark_partners'],
      },
      {
        title: 'Администраторы',
        to: 'admins',
        icon: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0312 11.9102C17.6912 11.9102 19.0212 10.5702 19.0212 8.91016C19.0212 7.25016 17.6912 5.91016 16.0312 5.91016C14.3713 5.91016 13.0312 7.25016 13.0312 8.91016C13.0312 10.5702 14.3713 11.9102 16.0312 11.9102ZM8.03125 11.9102C9.69125 11.9102 11.0212 10.5702 11.0212 8.91016C11.0212 7.25016 9.69125 5.91016 8.03125 5.91016C6.37125 5.91016 5.03125 7.25016 5.03125 8.91016C5.03125 10.5702 6.37125 11.9102 8.03125 11.9102ZM8.03125 13.9102C5.70125 13.9102 1.03125 15.0802 1.03125 17.4102V19.9102H15.0312V17.4102C15.0312 15.0802 10.3612 13.9102 8.03125 13.9102ZM16.0312 13.9102C15.7413 13.9102 15.4113 13.9302 15.0612 13.9602C16.2212 14.8002 17.0312 15.9302 17.0312 17.4102V19.9102H23.0312V17.4102C23.0312 15.0802 18.3612 13.9102 16.0312 13.9102Z" fill="#2E3A59"/>
          </svg>`,
        permission: ['bookmark_administrators'],
      },
      {
        title: 'Услуги',
        to: 'services',
        img: 'services',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.1557 14.792H6.28072C5.45572 14.792 4.66406 14.442 4.10572 13.8337C3.54739 13.2253 3.26406 12.4087 3.33073 11.5837L4.0224 3.28366C4.0474 3.02533 3.95572 2.77533 3.78072 2.58366C3.60572 2.392 3.36406 2.29199 3.10573 2.29199H1.66406C1.3224 2.29199 1.03906 2.00866 1.03906 1.66699C1.03906 1.32533 1.3224 1.04199 1.66406 1.04199H3.11407C3.7224 1.04199 4.29739 1.30032 4.70572 1.74199C4.93072 1.99199 5.09739 2.28366 5.18906 2.60866H15.5974C16.4391 2.60866 17.2141 2.94199 17.7807 3.54199C18.3391 4.15033 18.6224 4.94199 18.5557 5.78366L18.1057 12.0337C18.0141 13.5587 16.6807 14.792 15.1557 14.792ZM5.23073 3.85032L4.58073 11.6836C4.53906 12.167 4.6974 12.6253 5.0224 12.9836C5.3474 13.342 5.79739 13.5337 6.28072 13.5337H15.1557C16.0224 13.5337 16.8057 12.8003 16.8724 11.9337L17.3224 5.68366C17.3557 5.192 17.1974 4.72533 16.8724 4.38367C16.5474 4.03367 16.0974 3.84198 15.6057 3.84198H5.23073V3.85032Z" fill="#25325F"/>
          <path d="M13.5417 18.9583C12.625 18.9583 11.875 18.2083 11.875 17.2917C11.875 16.375 12.625 15.625 13.5417 15.625C14.4583 15.625 15.2083 16.375 15.2083 17.2917C15.2083 18.2083 14.4583 18.9583 13.5417 18.9583ZM13.5417 16.875C13.3083 16.875 13.125 17.0583 13.125 17.2917C13.125 17.525 13.3083 17.7083 13.5417 17.7083C13.775 17.7083 13.9583 17.525 13.9583 17.2917C13.9583 17.0583 13.775 16.875 13.5417 16.875Z" fill="#25325F"/>
          <path d="M6.8776 18.9583C5.96094 18.9583 5.21094 18.2083 5.21094 17.2917C5.21094 16.375 5.96094 15.625 6.8776 15.625C7.79427 15.625 8.54427 16.375 8.54427 17.2917C8.54427 18.2083 7.79427 18.9583 6.8776 18.9583ZM6.8776 16.875C6.64427 16.875 6.46094 17.0583 6.46094 17.2917C6.46094 17.525 6.64427 17.7083 6.8776 17.7083C7.11094 17.7083 7.29427 17.525 7.29427 17.2917C7.29427 17.0583 7.11094 16.875 6.8776 16.875Z" fill="#25325F"/>
          <path d="M17.5 7.29199H7.5C7.15833 7.29199 6.875 7.00866 6.875 6.66699C6.875 6.32533 7.15833 6.04199 7.5 6.04199H17.5C17.8417 6.04199 18.125 6.32533 18.125 6.66699C18.125 7.00866 17.8417 7.29199 17.5 7.29199Z" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_services'],
      },
      {
        title: 'Поддержка',
        to: 'support',
        img: 'support',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.66406 18.5998C6.43073 18.5998 6.18905 18.5415 5.97238 18.4249C5.49738 18.1749 5.20573 17.6748 5.20573 17.1415V15.9582C2.68906 15.6999 1.03906 13.8498 1.03906 11.1998V6.19987C1.03906 3.3332 2.96406 1.4082 5.83073 1.4082H14.1641C17.0307 1.4082 18.9557 3.3332 18.9557 6.19987V11.1998C18.9557 14.0665 17.0307 15.9915 14.1641 15.9915H11.0224L7.47237 18.3582C7.23071 18.5166 6.9474 18.5998 6.66406 18.5998ZM5.83073 2.64986C3.68073 2.64986 2.28906 4.04153 2.28906 6.19153V11.1916C2.28906 13.3416 3.68073 14.7332 5.83073 14.7332C6.1724 14.7332 6.45573 15.0166 6.45573 15.3582V17.1332C6.45573 17.2416 6.5224 17.2916 6.56407 17.3166C6.60573 17.3416 6.68907 17.3666 6.78074 17.3082L10.4891 14.8416C10.5891 14.7749 10.7141 14.7332 10.8391 14.7332H14.1724C16.3224 14.7332 17.7141 13.3416 17.7141 11.1916V6.19153C17.7141 4.04153 16.3224 2.64986 14.1724 2.64986H5.83073V2.64986Z" fill="#25325F"/>
          <path d="M10.0005 10.0918C9.65883 10.0918 9.3755 9.80846 9.3755 9.4668V9.29183C9.3755 8.32516 10.0838 7.85015 10.3505 7.66682C10.6588 7.45849 10.7588 7.31683 10.7588 7.10016C10.7588 6.68349 10.4172 6.3418 10.0005 6.3418C9.58383 6.3418 9.24219 6.68349 9.24219 7.10016C9.24219 7.44183 8.95885 7.72516 8.61719 7.72516C8.27552 7.72516 7.99219 7.44183 7.99219 7.10016C7.99219 5.99183 8.89217 5.0918 10.0005 5.0918C11.1088 5.0918 12.0088 5.99183 12.0088 7.10016C12.0088 8.05016 11.3088 8.52514 11.0505 8.70014C10.7255 8.91681 10.6255 9.0585 10.6255 9.29183V9.4668C10.6255 9.8168 10.3422 10.0918 10.0005 10.0918Z" fill="#25325F"/>
          <path d="M10 12.167C9.65 12.167 9.375 11.8837 9.375 11.542C9.375 11.2003 9.65833 10.917 10 10.917C10.3417 10.917 10.625 11.2003 10.625 11.542C10.625 11.8837 10.35 12.167 10 12.167Z" stroke-width="1.3" fill="#25325F"/>
          </svg>`,
        permission: ['bookmark_support'],
      },
      {
        title: 'Контроль доступа',
        to: 'access',
        img: 'access',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83594 8.33366V6.66699C5.83594 3.90866 6.53038 1.66699 10.0026 1.66699C13.4748 1.66699 14.1693 3.90866 14.1693 6.66699V8.33366" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.9974 15.4167C11.148 15.4167 12.0807 14.4839 12.0807 13.3333C12.0807 12.1827 11.148 11.25 9.9974 11.25C8.8468 11.25 7.91406 12.1827 7.91406 13.3333C7.91406 14.4839 8.8468 15.4167 9.9974 15.4167Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.75 18.333H6.25C3.25 18.333 2.5 17.4997 2.5 14.1663V12.4997C2.5 9.16634 3.25 8.33301 6.25 8.33301H13.75C16.75 8.33301 17.5 9.16634 17.5 12.4997V14.1663C17.5 17.4997 16.75 18.333 13.75 18.333Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
        permission: ['bookmark_accesscontrol'],
      },
      {
        title: 'Редактор тестов',
        to: 'edit-tests',
        img: 'access',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83594 8.33366V6.66699C5.83594 3.90866 6.53038 1.66699 10.0026 1.66699C13.4748 1.66699 14.1693 3.90866 14.1693 6.66699V8.33366" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.9974 15.4167C11.148 15.4167 12.0807 14.4839 12.0807 13.3333C12.0807 12.1827 11.148 11.25 9.9974 11.25C8.8468 11.25 7.91406 12.1827 7.91406 13.3333C7.91406 14.4839 8.8468 15.4167 9.9974 15.4167Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.75 18.333H6.25C3.25 18.333 2.5 17.4997 2.5 14.1663V12.4997C2.5 9.16634 3.25 8.33301 6.25 8.33301H13.75C16.75 8.33301 17.5 9.16634 17.5 12.4997V14.1663C17.5 17.4997 16.75 18.333 13.75 18.333Z" stroke="#25325F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
        permission: ['view_instrumentset'],
      },

    ],
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      loading: 'auth/loading',
      stock_balance: 'analytics/stock_balance',
      balanceLoading: 'analytics/loading',
      user: 'auth/user',
      notifications: 'notifications/notifications',
      allInstrumentSets: 'testEditor/instrumentSets'
    }),
    itemsMenu() {
      if (!this.user) return [];

      return this.items.filter((item) => !item.permission
        || !item.permission.length || this.$store.getters['auth/isAllowed'](item.permission));
    },
    instrumentSets() {
      return this.allInstrumentSets
        .filter(el => el.status === 'published')
        .filter(el => this.$store.getters['tests/isReportAllowed'](el.code))
        .map(el => ({
          title: el.name,
          key: 'CONSTRUCTOR_REPORT_' + el.code,
      }));
    },
    logo() {
      return require(process.env.VUE_APP_MODE === 'TEAM_ASM' ? '@/assets/images/logoTA.svg' : '@/assets/images/logo.svg')
    },
    ColumnsSetupLong() {
      let columns = [...ColumnsSetupLong.columns]
      if (this.instrumentSets?.length)
        columns = [...columns, ...this.instrumentSets]
      return {
        columnsKey: ColumnsSetupLong.columnsKey,
        columns 
      }
    },
    ColumnsSetupShort: () => ColumnsSetupShort,

  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      removeNotification: 'notifications/remove',
      loadInstrumentSets: 'testEditor/loadInstrumentSets'
    }),
    permittedChildren(item) {
      return item.children.filter((child) => !child.permission
        || !child.permission.length || this.$store.getters['auth/isAllowed'](child.permission));
    },
    onLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'home' });
      });
    },
    setDrawer(val) {
      this.drawer = val;
      localStorage.setItem('layoutDrawer', JSON.stringify(val));
    }
  },
  watch: {
    loggedIn(newVal) {
      if (newVal) this.$router.push({ name: 'home' });
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.drawer = JSON.parse(localStorage.getItem('layoutDrawer'));

    });
    await this.loadInstrumentSets();
  }

};
</script>
<style lang="scss">
.h-100 {
  height: 100%;
}

.icon-menu {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    // stroke: #25325F;
    // stroke-width: 1px;

  }
}

.v-list-item--active {
  background: #EEF7FC;
  border-radius: 8px;
  .icon-menu svg {
    stroke-width: 1px;
    // stroke: var(--v-primary-base);
  }
}


.profile-info {
  font-size: 14px;
}



</style>
