import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: process.env.VUE_APP_MODE === 'TEAM_ASM' ? '#41C32C' : '#18A0FB',
        secondary: process.env.VUE_APP_MODE === 'TEAM_ASM' ? '#41C32C' : '#18A0FB',
        accent: '#8c9eff',
        warning: '#EFAC00',
        error: '#FF6E6D',
        success: '#94D727',
      },
    },
  },
});
