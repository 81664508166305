<template>
  <div v-if="loading.codes" class="h-100 d-flex justify-center align-center">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </div>
  <div v-else class="page">
    <h2 class="page-title mb-8 mt-1">Промокоды по реферальной программе</h2>
    <div class="profile-board ref-board">
      <div class="row">
        <div class="col col-sm-8 pr-8">
          <div class="profile-title">
            Поделись <span style="color:var(--v-primary-base)">эффективным инструментом с коллегами</span> по цеху для проведения диагностики клиентов
          </div>
          <div class="mt-6">
            Отправьте ссылку коллегам - по ссылке они регистрируются на сайте, оплачивают один из пакетов
            <span v-html="curRef && curRef.tests_for_distributors ? `и вы получаете <b>${getTestStr(curRef.tests_for_distributors)} в подарок</b> ` : ''" />
            <span v-html="curRef && curRef.tests_for_users ? `и ваш коллега получает <b>${getTestStr(curRef.tests_for_users)} в подарок</b> ` : '' " />
          </div>

          <div class="share-rect">
            <div class="profile-title">
              Поделиться ссылкой
            </div>
            <div class="d-flex">
              <v-select v-model="curRef"
                      :items="codes"
                      :item-text="(item) => item.title"
                      item-value="id"
                      outlined return-object dense hide-details
                      class="mr-4 mb-2"
                    />

              <v-tooltip v-model="copied" top>
                <template v-slot:activator="{  }">
                  <v-btn color="primary" :disabled="!curRef" @click="refCopy()">Скопировать</v-btn>
                </template>
                <span>Скопировано</span>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.83789 8.6189H11.5046" stroke="var(--v-primary-base)" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.83789 5.95215L11.5046 8.61882L8.83789 11.2855" stroke="var(--v-primary-base)" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <a class="small-link" href="/promocodes">Смотреть все записи</a><br>
            </div>
            <div class="d-flex" v-if="$store.getters['auth/isAllowed']('view_referralnumber')">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.83789 8.6189H11.5046" stroke="var(--v-primary-base)" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.83789 5.95215L11.5046 8.61882L8.83789 11.2855" stroke="var(--v-primary-base)" stroke-width="1.06667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <a class="small-link" href="/reflinks">Реферальные ссылки</a><br>
            </div>

            <div class="social-links">
              <a href="https://web.whatsapp.com/" target="_blank"><img src="@/assets/images/refprogram/tg.png" /></a>
              <a href="https://vk.com/" target="_blank"><img src="@/assets/images/refprogram/vk.png" /></a>
              <a href="https://web.telegram.org/" target="_blank"><img src="@/assets/images/refprogram/whatsup.png" /></a>
            </div>

          </div>
        </div>
        <div class="col col-sm-4">
          <div class="hint">
            <div class="hint-item">
              <div class="hint-img"><img src="@/assets/images/refprogram/NewMail.png" /></div>
              <div>
                <div class="hint-title">Делитесь ссылкой</div>
                <div class="hint-text">Вы делитесь своей ссылкой любым удобным для вас способом</div>
              </div>
            </div>
            <div class="hint-item">
              <div class="hint-img"><img src="@/assets/images/refprogram/Safebox.png" /></div>
              <div>
                <div class="hint-title">Процесс оплаты</div>
                <div class="hint-text">Коллеги переходят по ссылке на сайт, регистрируются и оплачивают один из пакетов</div>
              </div>
            </div>
            <div class="hint-item">
              <div class="hint-img"><img src="@/assets/images/refprogram/Smartphone.png" /></div>
              <div>
                <div class="hint-title">Начисление тестов</div>
                <div class="hint-text">Вы получаете письмо, в котором мы сообщаем о начислении бонусных тестов вам и вашему коллеге</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { decOfNum } from '@/common/util';

export default {
  name: 'Candidate',
  components: {},
  data() {
    return {
      curRef: {},
      copied: false,
    };
  },
  computed: {
    ...mapGetters({
      codes: 'profile/refs/codes',
      defaultRef: 'profile/refs/defaultRef',
      loading: 'profile/refs/loading',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      loadCodes: 'profile/refs/loadCodes'
    }),
    // getTest1() {
    //   return this.curRef && this.curRef.tests_for_distributors ? 'и вы получаете <b>' + this.getTestStr(this.curRef.tests_for_distributors) + 'в подарок</b>' : '';
    // },
    getTestStr(i) {
      return `${i} ${decOfNum(i, ['тест', 'теста', 'тестов'])}`;
    },
    refCopy() {
      this.copied = true;
      setTimeout(() => { this.copied = false; }, 1500);
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(this.curRef.promocode);
        // navigator.clipboard.writeText(`https://sostaff.app/promocode/${this.curRef.promocode}`);
      }
    }
  },
  async created() {
    await this.loadCodes({
      user_type: this.user.user_type,
      id: this.user.id
    });
    this.curRef = this.defaultRef;
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="scss">
.ref-board {
  padding: 50px 40px;
}

.hint {
  background: #f5f8ff;
  border-radius: 30px;
  padding: 30px;
  .hint-item {
    display: flex;
    margin: 20px 0;
  }
  .hint-img {
    margin-right: 15px;
    img {
      width: 80px;
    }
  }
  .hint-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 134.4%;
    color: #2e3a59;
    margin-bottom: 8px;
  }
  .hint-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 134.4%;
  }
}

.share-rect {
  background: #F2FAFF;
  border-radius: 30px;
  padding: 20px 30px;
  margin: 30px 0;
}

.social-links {
  margin: 20px 0 0 0 ;
  display: flex;
  a {
    margin-right: 30px;
  }
}

.small-link {
  font-size: 10px;
  color: var(--v-primary-base);
  text-decoration: none;
}
</style>
