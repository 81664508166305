<template>
  <router-view v-if="initialized" :class="{'platform-ta': $store.getters['auth/isTA']}"/>
  <v-app v-else>
    <v-overlay color="#FFFFFF">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </v-overlay>
  </v-app>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      token: 'auth/token',
      initialized: 'auth/initialized',
    }),
  },
  methods: mapMutations({
    setInitialized: 'auth/setInitialized',
  }),
  created() {
    if (!this.token) this.setInitialized();
    console.log('19-06');
  },
};
</script>
